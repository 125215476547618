import { AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';
import { edubiServiceRequest, msGraphRequest, redirectRequest } from '../config/authConfig';

const { REACT_APP_AUTHORITY_URI } = process.env;

export const azureSigninPopup = (
  instance: IPublicClientApplication
): Promise<AuthenticationResult> => {
  return instance.loginPopup(msGraphRequest);
};

export const azureSigninRedirect = async (instance: IPublicClientApplication): Promise<void> => {
  return instance.loginRedirect(redirectRequest);
};

export const azureSignout = (instance: IPublicClientApplication): Promise<void> => {
  return instance.logoutPopup();
};

export const acquireGraphToken = (
  instance: IPublicClientApplication
): Promise<AuthenticationResult> => {
  return instance.acquireTokenSilent({
    scopes: msGraphRequest.scopes,
    account: instance.getAllAccounts()[0],
  });
};

export const acquireServiceToken = (
  instance: IPublicClientApplication
): Promise<AuthenticationResult> => {
  return instance.acquireTokenSilent({
    scopes: edubiServiceRequest.scopes,
    account: instance.getAllAccounts()[0],
    authority: `${REACT_APP_AUTHORITY_URI}/common`,
  });
};

export const buildHeaders = (accessToken: string) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${accessToken}`);
  return headers;
};
