import { UserRole } from '../types/user.d';

export const isEduBIAdminPage = () => window.location.host.split('.')[0] === 'admin';

export const isGlobalAdmin = (role: UserRole, isGuardian: boolean) =>
  isEduBIAdminPage() && !isGuardian && role === UserRole.GLOBAL_ADMIN;

export const isSupport = (role: UserRole, isGuardian: boolean) =>
  !isGuardian && role === UserRole.SUPPORT;

export const hasAdminAccess = (role: UserRole, isGuardian: boolean) =>
  !isGuardian && [UserRole.GLOBAL_ADMIN, UserRole.ADMIN, UserRole.SUPPORT].includes(role);
