import React, { useRef, useState, FormEvent } from 'react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';
import { useAddClientMutation } from '../../../../features/client/clientApi.slice';
import { useAppDispatch } from '../../../../features/hooks';
import { setErrorMessage, setSuccessMessage } from '../../../../features/layout/layout.slice';

interface AddClientModalProps {
  modalProps: ModalProps;
  handleClose: () => void;
}

function AddNewClientModal({ modalProps, handleClose }: AddClientModalProps) {
  const { show, onHide, centered, closeButton } = modalProps;
  const dispatch = useAppDispatch();
  const [addClient] = useAddClientMutation();
  const [name, setName] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const formRef = useRef<HTMLFormElement | null>(null);
  const [aadTenantId, setAADTenantId] = useState<string | null>('');
  const [aadTenantName, setAADTenantName] = useState<string | null>('');
  const [hasPBIEmbedded, setHasPBIEmbedded] = useState<boolean>(true);

  const submitNewClient = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!(name && code)) return;
    addClient({
      name,
      code,
      aadTenantId,
      aadTenantName,
      hasPBIEmbedded,
    })
      .unwrap()
      .then(() => {
        dispatch(setSuccessMessage('Client added succesfully'));
        handleClose();
      })
      .catch(() => dispatch(setErrorMessage('Unable to add client')));
  };

  const onTogglePBIEmbedded = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tenantId: string | null;
    let tenantName: string | null;
    const { checked } = event.target;
    if (!checked) {
      tenantId = null;
      tenantName = null;
    } else {
      tenantId = '';
      tenantName = '';
    }
    setAADTenantId(tenantId);
    setAADTenantName(tenantName);
    setHasPBIEmbedded(checked);
  };

  return (
    <Modal id="addClientModal" show={show} onHide={onHide} centered={centered}>
      <Modal.Header closeButton={closeButton} className="border-0 p-0">
        <Modal.Title className="modal-title">New Client</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Form id="addNewClient" ref={formRef} onSubmit={submitNewClient}>
          <div className="new-client-fields">
            <div className="form-subtitle-container">
              <Form.Text className="form-subtitle">CLIENT DETAILS</Form.Text>
              <Form.Switch
                label="Has Power BI Embedded"
                checked={hasPBIEmbedded}
                onChange={onTogglePBIEmbedded}
              />
            </div>
            <hr className="my-2" />
            <Row className="new-client-row">
              <Form.Group as={Col} className="new-client-col">
                <Form.Label className="form-label">Client name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Client name"
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                  className="form-input"
                  maxLength={32}
                />
              </Form.Group>
              <Form.Group as={Col} className="new-client-col">
                <Form.Label className="form-label">Client code</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Client code"
                  onChange={(e) => setCode(e.target.value)}
                  className="form-input"
                  maxLength={16}
                />
              </Form.Group>
            </Row>
            {hasPBIEmbedded && (
              <Row className="new-client-row">
                <Form.Group as={Col} className="new-client-col">
                  <Form.Label className="form-label">AAD Tenant name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Tenant name"
                    onChange={(e) => setAADTenantName(e.target.value)}
                    className="form-input"
                    maxLength={32}
                  />
                </Form.Group>
                <Form.Group as={Col} className="new-client-col">
                  <Form.Label className="form-label">AAD Tenant ID</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Tenant ID"
                    onChange={(e) => setAADTenantId(e.target.value)}
                    className="form-input"
                  />
                </Form.Group>
              </Row>
            )}
          </div>
          <div className="form-button">
            <Button type="submit" variant="primary">
              ADD NEW CLIENT
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddNewClientModal;
