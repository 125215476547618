import { IGroupReportsUsers } from './group.d';
import IPowerbi, { IPowerbiReports } from './powerbi.d';
import IUser from './user.d';

interface IClient {
  id: string;
  version: number;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  name: string;
  code: string;
  hasPBIEmbedded: boolean;
  aadTenantId: string | null;
  aadTenantName: string | null;
  lastSync: string;
  timeToSync: ClientTimeToSync;
  powerbiId: string;
  hasGuardians: boolean;
  guardiansGradeFrom: number;
  guardiansGradeTo: number;
  primaryYearsFrom: number | null;
  primaryYearsTo: number | null;
  middleYearsFrom: number | null;
  middleYearsTo: number | null;
  secondaryYearsFrom: number | null;
  secondaryYearsTo: number | null;
}

export interface IUpdateClientDto {
  clientId?: string;
  name?: string;
  code?: string;
  hasPBIEmbedded?: boolean;
  aadTenantId?: string | null;
  aadTenantName?: string | null;
  powerbiId?: string | null;
  hasGuardians?: boolean;
  guardiansGradeFrom?: number;
  guardiansGradeTo?: number;
  primaryYearsFrom?: number | null;
  primaryYearsTo?: number | null;
  middleYearsFrom?: number | null;
  middleYearsTo?: number | null;
  secondaryYearsFrom?: number | null;
  secondaryYearsTo?: number | null;
}

export interface IUpdateClientAdminsDto {
  clientId?: string;
  emailList?: string[];
}

export interface IUpdateClientAutoSyncTime {
  id: string;
  timeToSync: ClientTimeToSync;
}

export interface IClientPowerbiReports extends IClient {
  powerbi: IPowerbiReports;
}

export interface IClientPowerbi extends IClient {
  powerbi: IPowerbi;
}

export interface IClientGroupUsersReports extends IClient {
  groups: IGroupReportsUsers[];
}

export interface IClientUsers extends IClient {
  users: IUser[];
}

export interface IClientResources extends IClient {
  dataWarehouseStatus: ResourceStatus;
  pipelineVMStatus: ResourceStatus;
  airbyteVMStatus: ResourceStatus;
}

export enum ResourceTypes {
  DATA_WAREHOUSE = 'Data Warehouse',
  PIPELINE_VM = 'Pipeline Virtual Machine',
  AIRBYTE_VM = 'Airbyte Virtual Machine',
}

export enum ResourceStatus {
  READY = 'Ready',
  STARTING = 'Starting',
  STOPPED = 'Stopped',
  STOPPING = 'Stopping',
  UPDATING = 'Updating',
  ERROR = 'Error',
  ON_PREM = 'On-Prem',
}

export enum ClientTimeToSync {
  TIME_0 = '0',
  TIME_1 = '4',
  TIME_2 = '8',
  TIME_3 = '12',
  TIME_4 = '16',
  TIME_5 = '20',
}

export default IClient;
