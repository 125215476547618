import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import {
  useGetClientGroupsQuery,
  useUpdateAutoSyncTimeMutation,
} from '../../features/client/clientApi.slice';
import { IGroupReportsUsers } from '../../types/group.d';
import EditGroup from './EditGroup/EditGroup';
import { useAppDispatch, useAppSelector } from '../../features/hooks';
import { selectEditingGroup, setEditingGroup } from '../../features/settings/settings.slice';
import {
  useGetActiveDirectoryGroupsQuery,
  useLazyGetActiveDirectoryGroupMembersQuery,
} from '../../features/azure/azureApi.slice';
import SearchBox from '../SearchBox/SearchBox';
import { IActiveDirectoryGroup, IActiveDirectoryMember } from '../../types/azure.d';
import ADGroup from './ADGroup/ADGroup';
import { setErrorMessage, setSuccessMessage } from '../../features/layout/layout.slice';
import { ClientTimeToSync } from '../../types/client.d';
import { useDeleteGroupMutation } from '../../features/group/groupApi.slice';
import SyncButton, { SyncType } from '../SyncButton/SyncButton';

interface IGroupSettingsProps {
  clientId: string;
  readOnly: boolean;
}

function GroupSettings(props: IGroupSettingsProps): JSX.Element {
  const { clientId, readOnly } = props;
  const [deleteGroup] = useDeleteGroupMutation();

  /* App states */
  const dispatch = useAppDispatch();
  const { data: clientGroups } = useGetClientGroupsQuery(clientId);
  const [isAddingGroup, setIsAddingGroup] = useState<boolean>(false);

  /* Active Directory */
  const {
    data: adGroups,
    refetch: refetchAdGroups,
    isFetching,
  } = useGetActiveDirectoryGroupsQuery();
  const [updateAutoSyncTime] = useUpdateAutoSyncTimeMutation();
  const [getAdGroupMembers] = useLazyGetActiveDirectoryGroupMembersQuery();
  const [filteredGroupsAD, setFilteredGroupsAD] = useState<typeof adGroups>(adGroups);
  const [selectedGroup, setSelectedGroup] = useState<IActiveDirectoryGroup | null>(null);
  const [addingADGroup, setAddingADGroup] = useState<boolean>(false);
  const [selectedADGroup, setSelectedADGroup] = useState<IActiveDirectoryGroup | null>(null);
  const [selectedADGroupMembers, setSelectedADGroupMembers] = useState<
    IActiveDirectoryMember[] | null
  >(null);

  useEffect(() => {
    if ((!adGroups || adGroups?.length === 0) && !isFetching) {
      refetchAdGroups();
    }
  }, []);

  useEffect(() => {
    setFilteredGroupsAD(adGroups);
  }, [adGroups]);

  useEffect(() => {
    setSelectedGroup(null);
  }, [isAddingGroup]);

  const onADSearchChange = (input: string) => {
    setFilteredGroupsAD(
      adGroups?.filter((group) =>
        group.displayName.toLowerCase().trim().includes(input.toLowerCase().trim())
      )
    );
  };

  /* Select group button handler */
  const goToEditGroup = (group?: IGroupReportsUsers) => {
    setAddingADGroup(false);
    dispatch(setEditingGroup(group || clientGroups?.groups[0] || null));
  };

  const onADSearchSelect = async () => {
    if (!selectedGroup) return;
    const clientGroup = clientGroups?.groups?.find((g) => g.activeDirectoryId === selectedGroup.id);
    if (clientGroup) {
      goToEditGroup(clientGroup);
    } else {
      const { data: members } = await getAdGroupMembers(selectedGroup.id);
      setSelectedADGroup(selectedGroup);
      setAddingADGroup(true);
      setSelectedADGroupMembers(members ?? null);
    }
    setIsAddingGroup(false);
  };

  /* Selected group from existing groups */
  const editingGroup = useAppSelector(selectEditingGroup);

  const handleDelete = () => {
    if (!editingGroup) return;
    deleteGroup(editingGroup.id)
      .unwrap()
      .then(() => {
        dispatch(setEditingGroup(null));
        goToEditGroup();
        dispatch(setSuccessMessage('Group deleted'));
      })
      .catch(() => dispatch(setErrorMessage('Could not delete group')));
  };

  /* Update the selected group or set it to the first group in the list */
  useEffect(() => {
    if (clientGroups?.groups && clientGroups?.groups?.length > 0) {
      if (editingGroup)
        dispatch(
          setEditingGroup(
            clientGroups.groups.find((g) => g.id === editingGroup.id) || clientGroups.groups[0]
          )
        );
      else dispatch(setEditingGroup(clientGroups.groups[0]));
    }
  }, [clientGroups]);

  const onAutoSyncTimeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    await updateAutoSyncTime({
      id: clientId,
      timeToSync: event.target.value as ClientTimeToSync,
    })
      .unwrap()
      .then(() => dispatch(setSuccessMessage('Automatic sync time successfully updated')))
      .catch((err) =>
        dispatch(setErrorMessage(`Automatic sync time update failed: ${err.data.message}`))
      );
  };

  // Go through ClientTimeToSync enum possible key-values to find the client's current time to sync or get the first one as default
  // Get the first item ([0]) to retrieve the key which is needed to set the select's default value
  const getClientSyncTime = () =>
    Object.entries(ClientTimeToSync).reduce(
      (acc, [key, value]) => (value === clientGroups?.timeToSync ? [key, value] : acc),
      ['TIME_0', ClientTimeToSync.TIME_0]
    )[0];

  return (
    <div id="groupSettings">
      <span className="group-title">Groups</span>
      <div className="group-content">
        <div className="left-container">
          <div className="search-container">
            <div className="sync-btn-container">
              <SyncButton
                tooltip="Sync user and groups with Active Directory"
                type={SyncType.CLIENT}
                id={clientId}
              />
              <Button
                onClick={() => setIsAddingGroup(true)}
                className="add-group-btn"
                variant="secondary"
              >
                + Add new group from AD
              </Button>
            </div>
            {clientGroups?.lastSync && (
              <span className="last-sync">
                Last sync: {new Date(clientGroups.lastSync).toString()}
              </span>
            )}
            <div className="auto-sync-container">
              <span>Automatic sync time</span>
              <select
                id="auto-sync-select"
                value={getClientSyncTime()}
                onChange={onAutoSyncTimeChange}
                disabled={readOnly}
              >
                {Object.entries(ClientTimeToSync).map(([key, value]) => (
                  <option key={key} value={key}>
                    {`${value}:00`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="group-select-container">
            {clientGroups?.groups?.map((group) => (
              <div className="group-select" key={group.id}>
                <Button
                  className="group-select-btn"
                  onClick={() => goToEditGroup(group)}
                  active={editingGroup?.id === group.id && !addingADGroup}
                >
                  <span className="group-select-btn-name">{group.name}</span>
                </Button>
                {editingGroup?.id === group.id && !addingADGroup && (
                  <Button className="group-delete-btn" onClick={handleDelete}>
                    <Trash size={18} />
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="right-container">
          {editingGroup && !addingADGroup && (
            <EditGroup selectedGroup={editingGroup} clientId={clientId} readOnly={readOnly} />
          )}
          {addingADGroup && selectedADGroup && (
            <ADGroup
              onCancel={() => goToEditGroup()}
              clientId={clientId}
              adGroup={selectedADGroup}
              adGroupMembers={selectedADGroupMembers}
            />
          )}
        </div>
      </div>
      <Modal
        id="new-group-modal"
        show={isAddingGroup}
        onHide={() => setIsAddingGroup(false)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <Modal.Title className="group-modal-title">
            Search for Groups on Active Directory
          </Modal.Title>
          <SearchBox
            items={filteredGroupsAD ?? []}
            itemDisplayKey="displayName"
            itemKey="id"
            limit={100}
            searchLabel=""
            onChange={onADSearchChange}
            onItemClick={(group) => setSelectedGroup(group)}
            isLoading={isFetching}
          />
          {!!selectedGroup && (
            <div className="group-modal-selected">
              <span className="selected-group-label">ADDING:</span>
              <span className="selected-group-name">{selectedGroup.displayName}</span>
            </div>
          )}
          <div className="group-modal-btns">
            <Button
              className="group-modal-btn"
              variant="secondary"
              onClick={() => setIsAddingGroup(false)}
            >
              CANCEL
            </Button>
            <Button
              disabled={!selectedGroup}
              className="group-modal-btn"
              onClick={onADSearchSelect}
            >
              ADD
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GroupSettings;
