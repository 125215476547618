import { Configuration, PopupRequest, RedirectRequest } from '@azure/msal-browser';

const {
  REACT_APP_AUTHORITY_URI,
  REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URI,
  REACT_APP_SERVICE_SCOPE,
} = process.env;

if (!REACT_APP_SERVICE_SCOPE) throw new Error('EduBI service scope must be specified');

const clientCode = window.location.host.split('.')[0];
const redirectUri = `https://${clientCode}.${REACT_APP_REDIRECT_URI}`;

export const msalConfig: Configuration = {
  auth: {
    clientId: REACT_APP_CLIENT_ID ?? '',
    authority: `${REACT_APP_AUTHORITY_URI}/common`,
    redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const msGraphRequest: PopupRequest = {
  scopes: ['User.Read'],
};

export const redirectRequest: RedirectRequest = {
  scopes: ['User.Read'],
  redirectUri,
  redirectStartPage: redirectUri,
};

/**
 * When asking for tokens, only scopes for one API can be requested
 */
export const edubiServiceRequest: PopupRequest = {
  scopes: [REACT_APP_SERVICE_SCOPE],
};
