import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, ModalProps, Row, Spinner } from 'react-bootstrap';
import {
  TrashFill,
  PencilFill,
  PersonFill,
  XCircleFill,
  CheckCircleFill,
} from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import {
  useLazyGetClientUsersQuery,
  useLazyGetClientQuery,
  useUpdateClientMutation,
  useUpdateClientAdminsMutation,
} from '../../../../features/client/clientApi.slice';
import { UserRole } from '../../../../types/user.d';
import { IUpdateClientDto } from '../../../../types/client.d';
import { SchoolYearSections } from '../../../../types/report.d';
import { setErrorMessage, setSuccessMessage } from '../../../../features/layout/layout.slice';

interface EditClientModalProps {
  modalProps: ModalProps;
  clientId: string | null;
  clientName: string | null;
}

// TODO: Improve form (errors, saving edit, etc...)
function EditClientModal(props: EditClientModalProps) {
  const { clientId, clientName, modalProps } = props;
  const { show, onHide, centered, closeButton } = modalProps;
  const dispatch = useDispatch();

  /* Client Related */
  const [updateClient] = useUpdateClientMutation();
  const clientFormRef = useRef<HTMLFormElement | null>(null);
  const [clientUpdate, setClientUpdate] = useState<IUpdateClientDto>({});
  const [getClient, { data: client, isFetching }] = useLazyGetClientQuery();

  /* Client Admins Related */
  const [getClientAdmins] = useLazyGetClientUsersQuery();
  const [updateClientAdmins] = useUpdateClientAdminsMutation();
  const adminUserFormRef = useRef<HTMLFormElement | null>(null);
  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [editedAdmin, setEditedAdmin] = useState<string | null>(null); // Admin storing the new email input value
  const [originalEmailEdit, setOriginalEmailEdit] = useState<string | null>(null); // Admin with former email value to differentiate from map list
  const [adminEmailsTemp, setAdminEmailsTemp] = useState<string[]>([]);
  const [addingNewAdmin, setAddingNewAdmin] = useState<boolean>(false);
  const [newAdminEmail, setNewAdminEmail] = useState<string>('');

  const initiateAdminEdit = (email: string): void => {
    setOriginalEmailEdit(email);
    setAdminEmailsTemp(adminEmails.filter((adminEmail) => adminEmail !== email));
    setEditedAdmin(email);
  };

  const deleteAdmin = (email: string): void => {
    setAdminEmails(adminEmails.filter((adminEmail) => adminEmail !== email));
  };

  const saveAdminHandler = (): void => {
    if (editedAdmin) setAdminEmails([...adminEmailsTemp, editedAdmin]);
    else {
      dispatch(setErrorMessage('Please provide an email'));
      return;
    }
    setEditedAdmin(null);
    setOriginalEmailEdit(null);
    setAdminEmailsTemp([]);
  };

  const saveNewAdmin = (): void => {
    if (newAdminEmail) {
      setAdminEmails([...adminEmails, newAdminEmail]);
      setAddingNewAdmin(false);
    }
  };

  const submitUpdateClientAdmins = (): void => {
    if (clientId) {
      updateClientAdmins({ clientId, emailList: adminEmails })
        .unwrap()
        .then(() => dispatch(setSuccessMessage('Admins succesfully updated')))
        .catch((err) => dispatch(setErrorMessage(`Admins update failed: ${err.data.message}`)));
    }
  };

  const onTogglePBIEmbedded = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tenantId: string | null;
    let tenantName: string | null;
    const { checked } = event.target;
    if (!checked) {
      tenantId = null;
      tenantName = null;
    } else {
      tenantId = client?.aadTenantId ?? '';
      tenantName = client?.aadTenantName ?? '';
    }
    setClientUpdate({
      ...clientUpdate,
      aadTenantId: tenantId,
      aadTenantName: tenantName,
      hasPBIEmbedded: checked,
    });
  };

  const onToggleGuardians = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientUpdate({
      ...clientUpdate,
      guardiansGradeFrom: 1,
      guardiansGradeTo: 12,
      hasGuardians: event.target.checked,
    });
  };

  /**
   * Given a school section (primary, middle, secondary) it returns the available years for the section.
   * @param schoolSection Enum value (primary, middle or secondary)
   * Given the school section it uses the min and max school years the client has (guardiansGradeFrom/guardiansGradeTo) as well as the min and max set for other sections to return only the available options.
   * E.g. Given secondary section, for min it would first check middle's max, if it's null then it would check primary's max and if it's null it defaults to clients guardiansGradeFrom.
   * It checks the expected closest limits until it has the min/max for the section and returns the options array built using those limits.
   * @returns Array of available years (numbers) for the school section passed as param
   */
  const getAvailableSchoolYears = (schoolSection: SchoolYearSections): number[] => {
    if (!client) return [];
    let min = 1;
    let max = -1;
    if (schoolSection === SchoolYearSections.PRIMARY) {
      min = client.guardiansGradeFrom;
      max +=
        clientUpdate.middleYearsFrom ??
        clientUpdate.secondaryYearsFrom ??
        client.guardiansGradeTo + 1;
    } else if (schoolSection === SchoolYearSections.MIDDLE) {
      min += clientUpdate.primaryYearsTo ?? client.guardiansGradeFrom - 1;
      max += clientUpdate.secondaryYearsFrom ?? client.guardiansGradeTo + 1;
    } else {
      min +=
        clientUpdate.middleYearsTo ?? clientUpdate.primaryYearsTo ?? client.guardiansGradeFrom - 1;
      max = client.guardiansGradeTo;
    }
    return Array.from(Array(max - min + 1).keys()).map((k) => k + min);
  };

  const onToggleSchoolSection = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const years = getAvailableSchoolYears(target.name as SchoolYearSections);
    setClientUpdate({
      ...clientUpdate,
      [`${target.name.toLocaleLowerCase()}YearsFrom`]: target.checked ? years[0] : null,
      [`${target.name.toLocaleLowerCase()}YearsTo`]: target.checked
        ? years[years.length - 1]
        : null,
    });
  };

  useEffect(() => {
    if (clientId) {
      getClient(clientId)
        .unwrap()
        .then((c) =>
          setClientUpdate({
            ...clientUpdate,
            clientId: c.id,
            hasPBIEmbedded: c.hasPBIEmbedded,
            hasGuardians: c.hasGuardians,
            primaryYearsFrom: c.primaryYearsFrom,
            primaryYearsTo: c.primaryYearsTo,
            middleYearsFrom: c.middleYearsFrom,
            middleYearsTo: c.middleYearsTo,
            secondaryYearsFrom: c.secondaryYearsFrom,
            secondaryYearsTo: c.secondaryYearsTo,
          })
        )
        .catch(() => dispatch(setErrorMessage('Unable to get client details')));
      getClientAdmins({ clientId, role: UserRole.ADMIN })
        .unwrap()
        .then((clientAdmins) => setAdminEmails(clientAdmins.map(({ email }) => email)));
    }
  }, [clientId]);

  return (
    <Modal id="editClientModal" show={show} onHide={onHide} centered={centered} scrollable>
      <Modal.Header closeButton={closeButton} className="border-0 p-0">
        <Modal.Title className="modal-title">{clientName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFetching && (
          <div id="modalSpinner">
            <Spinner animation="border" />
          </div>
        )}
        {!isFetching && (
          <>
            <Form
              id="editClientForm"
              ref={clientFormRef}
              onSubmit={(e) => {
                e.preventDefault();
                updateClient(clientUpdate)
                  .unwrap()
                  .then(() => dispatch(setSuccessMessage('Client updated successfully')))
                  .catch((err) =>
                    dispatch(setErrorMessage(`Client update failed: ${err.data.message}`))
                  );
              }}
              className="edit-client-fields"
            >
              <div className="form-subtitle-container">
                <Form.Text className="form-subtitle">CLIENT DETAILS</Form.Text>
              </div>
              <hr className="my-2" />
              <Row className="edit-client-row">
                <Form.Group as={Col} className="edit-client-col">
                  <Form.Label className="form-label">Client name</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={client?.name}
                    onChange={(e) => setClientUpdate({ ...clientUpdate, name: e.target.value })}
                    className="form-input"
                    maxLength={32}
                  />
                </Form.Group>
                <Form.Group as={Col} className="edit-client-col">
                  <Form.Label className="form-label">Client code</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={client?.code}
                    onChange={(e) => setClientUpdate({ ...clientUpdate, code: e.target.value })}
                    className="form-input"
                    maxLength={16}
                  />
                </Form.Group>
              </Row>
              <Row className="edit-client-switch">
                <Form.Label className="form-label">Has Power BI Embedded</Form.Label>
                <Form.Switch
                  defaultChecked={client?.hasPBIEmbedded}
                  onChange={onTogglePBIEmbedded}
                />
              </Row>
              {clientUpdate?.hasPBIEmbedded && (
                <Row className="edit-client-row">
                  <Form.Group as={Col} className="edit-client-col">
                    <Form.Label className="form-label">AAD Tenant Name</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={client?.aadTenantName ?? ''}
                      onChange={(e) =>
                        setClientUpdate({ ...clientUpdate, aadTenantName: e.target.value })
                      }
                      className="form-input"
                      maxLength={32}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="edit-client-col">
                    <Form.Label className="form-label">AAD Tenant ID</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={client?.aadTenantId ?? ''}
                      onChange={(e) =>
                        setClientUpdate({ ...clientUpdate, aadTenantId: e.target.value })
                      }
                      className="form-input"
                    />
                  </Form.Group>
                </Row>
              )}
              <Row className="edit-client-switch">
                <Form.Label className="form-label">Has guardians</Form.Label>
                <Form.Switch defaultChecked={client?.hasGuardians} onChange={onToggleGuardians} />
              </Row>
              {clientUpdate?.hasGuardians && (
                <>
                  <Row className="edit-client-row">
                    <Form.Group as={Col} className="edit-client-col">
                      <Form.Label className="form-label">School Grade Years From</Form.Label>
                      <Form.Select
                        className="guardian-year-select"
                        defaultValue={client?.guardiansGradeFrom ?? ''}
                        onChange={(e) =>
                          setClientUpdate({ ...clientUpdate, guardiansGradeFrom: +e.target.value })
                        }
                      >
                        {Array.from(Array(12).keys()).map((i) => (
                          <option key={`primary-min-${i}`}>{i + 1}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="edit-client-col">
                      <Form.Label className="form-label">School Grade Years To</Form.Label>
                      <Form.Select
                        className="guardian-year-select"
                        defaultValue={client?.guardiansGradeTo ?? ''}
                        onChange={(e) =>
                          setClientUpdate({ ...clientUpdate, guardiansGradeTo: +e.target.value })
                        }
                      >
                        {Array.from(Array(12).keys()).map((i) => (
                          <option key={`primary-min-${i}`}>{i + 1}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="edit-client-row" id="guardian-year-selects">
                    <Form.Group as={Col} className="edit-client-col">
                      <div className="year-select-title">
                        <Form.Label className="form-label">Primary Years</Form.Label>
                        <Form.Switch
                          name={SchoolYearSections.PRIMARY}
                          onChange={onToggleSchoolSection}
                          defaultChecked={
                            !!clientUpdate.primaryYearsFrom && !!clientUpdate.primaryYearsTo
                          }
                        />
                      </div>
                      {!!clientUpdate.primaryYearsFrom && !!clientUpdate.primaryYearsTo && (
                        <div className="d-flex align-items-center">
                          <Form.Select
                            defaultValue={clientUpdate.primaryYearsFrom}
                            className="guardian-year-select"
                            onChange={(e) =>
                              setClientUpdate({
                                ...clientUpdate,
                                primaryYearsFrom: +e.target.value,
                              })
                            }
                          >
                            {getAvailableSchoolYears(SchoolYearSections.PRIMARY).map((i) => (
                              <option key={`primary-min-${i}`}>{i}</option>
                            ))}
                          </Form.Select>
                          <Form.Text className="mx-1"> to </Form.Text>
                          <Form.Select
                            defaultValue={clientUpdate.primaryYearsTo}
                            className="guardian-year-select"
                            onChange={(e) =>
                              setClientUpdate({ ...clientUpdate, primaryYearsTo: +e.target.value })
                            }
                          >
                            {getAvailableSchoolYears(SchoolYearSections.PRIMARY).map((i) => (
                              <option key={`primary-max-${i}`}>{i}</option>
                            ))}
                          </Form.Select>
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} className="edit-client-col">
                      <div className="year-select-title">
                        <Form.Label className="form-label">Middle Years</Form.Label>
                        <Form.Switch
                          name={SchoolYearSections.MIDDLE}
                          onChange={onToggleSchoolSection}
                          defaultChecked={
                            !!clientUpdate.middleYearsFrom && !!clientUpdate.middleYearsTo
                          }
                        />
                      </div>
                      {!!clientUpdate.middleYearsFrom && !!clientUpdate.middleYearsTo && (
                        <div className="d-flex align-items-center">
                          <Form.Select
                            defaultValue={clientUpdate.middleYearsFrom}
                            className="guardian-year-select"
                            onChange={(e) =>
                              setClientUpdate({ ...clientUpdate, middleYearsFrom: +e.target.value })
                            }
                          >
                            {getAvailableSchoolYears(SchoolYearSections.MIDDLE).map((i) => (
                              <option key={`middle-min-${i}`}>{i}</option>
                            ))}
                          </Form.Select>
                          <Form.Text className="mx-1"> to </Form.Text>
                          <Form.Select
                            defaultValue={clientUpdate.middleYearsTo}
                            className="guardian-year-select"
                            onChange={(e) =>
                              setClientUpdate({ ...clientUpdate, middleYearsTo: +e.target.value })
                            }
                          >
                            {getAvailableSchoolYears(SchoolYearSections.MIDDLE).map((i) => (
                              <option key={`middle-max-${i}`}>{i}</option>
                            ))}
                          </Form.Select>
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} className="edit-client-col">
                      <div className="year-select-title">
                        <Form.Label className="form-label">Secondary Years</Form.Label>
                        <Form.Switch
                          name={SchoolYearSections.SECONDARY}
                          onChange={onToggleSchoolSection}
                          defaultChecked={
                            !!clientUpdate.secondaryYearsFrom && !!clientUpdate.secondaryYearsTo
                          }
                        />
                      </div>
                      {!!clientUpdate.secondaryYearsFrom && !!clientUpdate.secondaryYearsTo && (
                        <div className="d-flex align-items-center">
                          <Form.Select
                            defaultValue={clientUpdate.secondaryYearsFrom}
                            className="guardian-year-select"
                            onChange={(e) =>
                              setClientUpdate({
                                ...clientUpdate,
                                secondaryYearsFrom: +e.target.value,
                              })
                            }
                          >
                            {getAvailableSchoolYears(SchoolYearSections.SECONDARY).map((i) => (
                              <option key={`secondary-min-${i}`}>{i}</option>
                            ))}
                          </Form.Select>
                          <Form.Text className="mx-1"> to </Form.Text>
                          <Form.Select
                            defaultValue={clientUpdate.secondaryYearsTo}
                            className="guardian-year-select"
                            onChange={(e) =>
                              setClientUpdate({
                                ...clientUpdate,
                                secondaryYearsTo: +e.target.value,
                              })
                            }
                          >
                            {getAvailableSchoolYears(SchoolYearSections.SECONDARY).map((i) => (
                              <option key={`secondary-max-${i}`}>{i}</option>
                            ))}
                          </Form.Select>
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                </>
              )}
              <div className="form-button">
                <Button type="submit" variant="primary">
                  UPDATE CLIENT
                </Button>
              </div>
            </Form>
            <Form
              id="editAdminUserForm"
              ref={adminUserFormRef}
              onSubmit={(e) => {
                e.preventDefault();
                submitUpdateClientAdmins();
              }}
              className="edit-client-fields"
            >
              <Form.Text className="form-subtitle"> CLIENT ADMIN USERS </Form.Text>
              <hr className="my-2" />
              {adminEmails.map((email) => (
                <Form.Group className="existing-client-admins" key={email}>
                  <Form.Control
                    type="text"
                    defaultValue={email}
                    disabled={originalEmailEdit !== email}
                    onChange={(e) => setEditedAdmin(e.target.value)}
                    className="form-input my-1"
                  />
                  {originalEmailEdit !== email && (
                    <PencilFill
                      type="button"
                      className="client-admin-icon edit-admin-icon"
                      onClick={() => initiateAdminEdit(email)}
                    />
                  )}
                  {originalEmailEdit === email && (
                    <CheckCircleFill
                      type="button"
                      className="client-admin-icon confirm-admin-icon"
                      onClick={() => saveAdminHandler()}
                    />
                  )}
                  {originalEmailEdit !== email && (
                    <TrashFill
                      type="button"
                      className="client-admin-icon delete-admin-icon"
                      onClick={() => deleteAdmin(email)}
                    />
                  )}
                  {originalEmailEdit === email && (
                    <XCircleFill
                      type="button"
                      className="client-admin-icon cancel-admin-icon"
                      onClick={() => setOriginalEmailEdit(null)}
                    />
                  )}
                </Form.Group>
              ))}
              {adminEmails.length === 0 && (
                <p className="client-admin-empty">
                  <i>No Admin Users for this client</i>
                </p>
              )}
              {addingNewAdmin && (
                <Form.Group className="existing-client-admins">
                  <Form.Control
                    type="text"
                    onChange={(e) => setNewAdminEmail(e.target.value)}
                    autoFocus
                  />
                  <CheckCircleFill
                    type="button"
                    className="client-admin-icon confirm-admin-icon form-input my-1"
                    onClick={() => saveNewAdmin()}
                  />
                  <XCircleFill
                    type="button"
                    className="client-admin-icon cancel-admin-icon"
                    onClick={() => {
                      setNewAdminEmail('');
                      setAddingNewAdmin(false);
                    }}
                  />
                </Form.Group>
              )}
              <Button
                className="add-new-client-btn"
                variant="outline-secondary"
                disabled={addingNewAdmin}
                onClick={() => setAddingNewAdmin(true)}
              >
                <PersonFill />
                <span>Add new Admin user</span>
              </Button>
              <div className="form-button">
                <Button type="submit" variant="primary">
                  UPDATE USERS
                </Button>
              </div>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default EditClientModal;
